import React, { Suspense } from 'react';
import '@v3/Utils/lang';
import { render } from 'react-dom';
import { createInertiaApp } from '@inertiajs/react';
import axios from 'axios';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { partnerTheme, theme } from './v2/utils/theme';
import '../sass/app.inertia.scss';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

// @ts-ignore
window.Pusher = Pusher;
// @ts-ignore
window.Echo = new Echo(window._ECHO_);

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Prototype Hubs';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
  // @ts-ignore
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

const Layout = (page) => {
  return (
    <SnackbarProvider maxSnack={20}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={page.props.viewType === 'plugin' ? partnerTheme : theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          {page}
        </ThemeProvider>
      </StyledEngineProvider>
    </SnackbarProvider>
  );
};

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: async (name) => {
    const page = await resolvePageComponent(`./v3/Pages/${name}.tsx`, import.meta.glob('./v3/Pages/**/*.tsx'));

    // @ts-ignore
    page.default.layout = Layout;

    return page;
  },
  setup({ el, App, props }) {
    return render(
      <Suspense fallback={<div />}>
        <App {...props} />
      </Suspense>,
      el,
    );
  },
  progress: {
    color: '#4B5563',
  },
});
